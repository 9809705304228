import React, { useState } from 'react';
import { FaFacebookSquare, FaWrench, FaMapMarkerAlt, FaCogs, FaEnvelope, FaPhone, FaClock } from 'react-icons/fa';
import logo from './assets/Logo.png';

function App() {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Navn er påkrævet';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email er påkrævet';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email format er ugyldigt';
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      // Form submission to Google Forms
      const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScWFl3eFugOgMxV-TmnZSF8jCYi08YZf76j_ktH1Tvk5q9LUw/formResponse';

      const data = new FormData();
      data.append('entry.1069344981', formData.name);    // Name field
      data.append('entry.765732189', formData.email);   // Email field
      data.append('entry.141650310', formData.phone);   // Phone field
      data.append('entry.300515466', formData.message);// Message field
      
      fetch(formUrl, {
        method: 'POST',
        mode: 'no-cors',
        body: data,
      })
        .then(() => {
          setFormSubmitted(true); // Set form submission state to true
          setFormData({ name: '', email: '', phone: '', message: '' }); // Clear the form
        })
        .catch((error) => console.error('Error submitting form:', error));
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <div className="font-sans text-gray-900">
      {/* Header */}
      <header className="bg-gray-800 text-white py-4 md:py-6 w-full">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
          <div className="flex flex-col md:flex-row items-center">
            <img src={logo} alt="Company Logo" className="h-12 md:h-20 mr-0 md:mr-4" />
            <h1 className="text-xl md:text-2xl font-bold text-center md:text-left">Alles Hvidevarer Service</h1>
          </div>
          <div className="flex items-center space-x-4 md:space-x-8 mt-2 md:mt-0">
            <nav className="flex space-x-4 md:space-x-6 text-sm md:text-lg">
              <a href="#home" className="hover:text-blue-400">Forside</a>
              <a href="#about" className="hover:text-blue-400">Om Os</a>
              <a href="#services" className="hover:text-blue-400">Ydelser</a>
              <a href="#contact" className="hover:text-blue-400">Kontakt Os</a>
            </nav>
            <a href="https://www.facebook.com/AllesHvidevareService" target="_blank" rel="noopener noreferrer" className="text-white hover:text-blue-400">
              <FaFacebookSquare className="text-2xl md:text-3xl" />
            </a>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main id="home" className="container mx-auto px-4 py-10">
        {/* About Section */}
        <section id="about" className="text-center my-16">
          <h2 className="text-3xl font-bold mb-4">Om Os</h2>
          <p className="text-lg max-w-6xl mx-auto">
            
Vi tilbyder professionel service og reparation af alle typer hvidevarer, så dine apparater kan holde længere og fungere optimalt. Med mange års erfaring i branchen er vi din pålidelige partner, der sikrer, at dine hvidevarer får den bedste pleje og vedligeholdelse. Ved at forlænge levetiden på dine produkter hjælper vi dig med at spare penge på dyre udskiftninger, samtidig med at du bidrager til en mere bæredygtig fremtid. 
Vælg os for effektiv service, der giver værdi – både for dine hårde hvidevarer og din økonomi.
          </p>
        </section>

        {/* Custom Full-Width Background with Centered Content */}
        <section id="services" className="relative">
          <div className="absolute inset-0 bg-gray-100"></div> {/* Full-width gray background */}
          <div className="relative max-w-screen-xl mx-auto py-12 px-4">
            <h2 className="text-3xl font-bold text-center mb-8">Vores Ydelser</h2>
            <div className="flex flex-wrap justify-center gap-8">
              <div className="bg-white p-6 shadow-lg rounded-lg max-w-xs text-center">
                <FaWrench className="text-blue-500 text-4xl mb-4" />
                <h3 className="text-2xl font-bold mb-4">Reparation</h3>
                <p>
                  Med over 30 års erfaring tilbyder vi reparationer af hårde hvidevarer. Vores erfarne tekniker kan hurtigt diagnosticere og reparere fejl på en bred vifte af mærker, herunder Fisher & Paykel, Smeg, Samsung, Bosch, Siemens, Gorenje, Indesit, Ariston, Hotpoint, Electrolux, Miele, Bauknecht, Whirlpool, AEG og mange flere.
                </p>
              </div>
              <div className="bg-white p-6 shadow-lg rounded-lg max-w-xs text-center">
                <FaCogs className="text-blue-500 text-4xl mb-4" />
                <h3 className="text-2xl font-bold mb-4">Service</h3>
                <p>
                  Vi tilbyder omfattende service på alle typer hårde hvidevarer, som vaskemaskiner, køleskabe, komfurer, opvaskemaskiner, tørretumblere, ovne og emhætter. 
                  Regelmæssig service er afgørende for at sikre, at dine apparater fungerer optimalt og har en lang levetid.  
                </p>
              </div>
              <div className="bg-white p-6 shadow-lg rounded-lg max-w-xs text-center">
                <FaMapMarkerAlt className="text-blue-500 text-4xl mb-4" />
                <h3 className="text-2xl font-bold mb-4">Dækningsområde</h3>
                <p>
                  Vores serviceområde dækker det meste af Sjælland, herunder Storkøbenhavn, Roskilde-området, Køge Bugt, Midtsjælland og 
                  flere omkringliggende områder.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-8 md:py-10 w-full bg-white">
          <div className="flex flex-col md:flex-row justify-center items-center md:justify-between mx-auto max-w-screen-xl px-4">
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">Kontakt Os</h2>
              <p className="text-base md:text-lg mb-4">
              Kontakt os i dag for at få professionel hjælp til dine hvidevarer. Vi tilbyder reparationer, der ikke kun forlænger levetiden på dine hårde hvidevarer, men også sparer miljøet for unødig brug af ressourcer og reducerer affald. 
              Vi kommer gerne med et uforpligtende tilbud.</p>
              <div className="space-y-4">
                <p className="text-xl font-bold">Henrik Færch</p>
                <p><FaEnvelope className="inline text-blue-500 mr-2" />Email: <a href="mailto:Alleshs.14@gmail.com" className="text-blue-600">Alleshs.14@gmail.com</a></p>
                <p><FaPhone className="inline text-blue-500 mr-2" />Telefon: <a href="tel:+4553551412" className="text-blue-600">+45 53 55 14 12</a></p>
                <p className="font-bold"><FaClock className="inline text-blue-500 mr-2" />Åbningstider:</p>
                <p>Man-Fre: 8-22.00</p>
                <p>Lørdag: 8-18.00</p>
              </div>
            </div>

            {/* Contact Form */}
            <div className="w-full md:w-1/2">
              {formSubmitted ? (
                <div className="bg-green-100 p-4 rounded-lg text-green-700">
                  Tak for din henvendelse, vi vender tilbage hurtigst muligt.
                </div>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  className="bg-white p-6 md:p-8 shadow-lg rounded-lg"
                >
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                      Navn
                    </label>
                    <input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        formErrors.name && 'border-red-500'
                      }`}
                      id="name"
                      type="text"
                      placeholder="Dit navn"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.name && <p className="text-red-500 text-xs italic">{formErrors.name}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                      Email
                    </label>
                    <input
                      className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                        formErrors.email && 'border-red-500'
                      }`}
                      id="email"
                      type="email"
                      placeholder="Din email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    {formErrors.email && <p className="text-red-500 text-xs italic">{formErrors.email}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                      Telefon (valgfri)
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="phone"
                      type="tel"
                      placeholder="Dit telefonnummer"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                    {formErrors.phone && <p className="text-red-500 text-xs italic">{formErrors.phone}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                      Besked
                    </label>
                    <textarea
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="message"
                      placeholder="Hvad drejer din henvendelse sig om?"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button
                      className="bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center space-x-2 transform transition-transform duration-300 ease-in-out hover:scale-105"
                      type="submit"
                    >
                      <FaEnvelope className="inline-block text-white mr-2" />
                      <span>Send Besked</span>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white text-center py-4">
        <div className="text-center px-4 md:px-6">
          <p>Alles Hvidevarer Service · Christians Torv 118 · 2680 Solrød Strand · Danmark · CVR: 35956204</p>
          <hr className="border-gray-600 my-2"/>
          <p className="text-xs mt-1">© 2024 · Designet af Jonas Gagner</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
